<template>
  <div class="container mb-3 mt-3">
    <table class="table table-striped table-bordered add-manage-table table demo footable-loaded footable"
           data-filter="#filter" data-filter-text-only="true">
      <thead>
      <tr>
        <th>Author</th>
        <th>Task Owner</th>
        <th>Task Title</th>
        <th>Rating</th>
        <th>Title</th>
        <th>Review</th>
        <th>Date Deleted</th>
        <th>Deleted by</th>
        <th>Reason</th>
        <th>Action</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(review, index) in reviewData" :key="index">
        <td class="cursor-pointer" @click="$router.push('/' + review.review.author_user.username)">{{ review.review.author_user.username }}</td>
        <td class="cursor-pointer" @click="$router.push('/' + review.review.user.username)">{{ review.review.user.username }}</td>
        <td class="cursor-pointer" @click="$router.push('/' + review.review.user.username + '/' + review.review.gig.slug)">{{ review.review.gig.title }}</td>
        <td>{{ review.review.rating }}</td>
        <td>{{ review.review.title !== null ? review.review.title : 'NA'  }}</td>
        <td>{{ review.review.message }}</td>
        <td>{{ date(review.review.date_deleted) }}</td>
        <td>{{ review.delete_by === null ? 'NA' : review.delete_by }}</td>
        <td>{{ review.delete_reason === null ? 'NA' : review.delete_reason }}</td>
        <td>
          <b-button v-if="!review.review.date_deleted" class=" w-100" variant="danger" @click="deleteReview(review.review.hash, index)">Remove Review</b-button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import ApiService from '../../../apiService'

export default {
  name: 'AdminReviewTable',
  props: {
    reviews: Array
  },
  data () {
    return {
      reviewData: this.reviews
    }
  },
  methods: {
    deleteReview (hash, index) {
      let vm = this
      let reason = ''
      this.$swal({
        title: 'Delete Review',
        text: 'Are you sure you want to delete this review?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Delete review',
        showCloseButton: true,
        showLoaderOnConfirm: true,
        input: 'textarea',
        inputPlaceholder: 'Please type your reason here for deleting a review',
        inputValidator: (value) => {
          if (!value) {
            return 'Please enter a reason'
          }
        },
        preConfirm: (result) => {
          if (result !== '') {
            reason = result
            return ApiService.adminDeleteReview(hash, result).then(() => {
            }).catch((error) => {
              this.$swal.showValidationMessage(
                error.response.data.error_message
              )
            })
          }
        }
      }).then((result) => {
        if (result.value !== '' && result.dismiss == null) {
          this.$swal({
            title: 'Success',
            text: 'Review has been deleted',
            type: 'success',
            confirmButtonText: 'Close'
          }).then(() => {
            vm.reviewData[index].delete_by = localStorage.getItem('username')
            vm.reviewData[index].delete_reason = reason
            vm.reviewData[index].review.date_deleted = new Date()
          })
        }
      })
    },
    date (date) {
      if (date === null) {
        return 'NA'
      }
      const newDate = new Date(date)
      const todayDate = new Date()

      if (newDate.setHours(0, 0, 0, 0) === todayDate.setHours(0, 0, 0, 0)) {
        return new Date(date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })
      } else {
        return newDate.toLocaleDateString()
      }
    }
  }
}
</script>

<style scoped>

</style>
