<template>
  <div>
    <div class="container">
      <Breadcrumb :breadcrumbList="[ { 'name': 'ADMIN DASHBOARD ', 'link': '/admin' },{ 'name': 'REVIEWS' }]"/>
      <div class="main-container">
        <h1><strong>Manage Reviews</strong></h1>
        <div class="container">
          <div class="col-xl-12 content-box">
            <div class="row row-featured row-featured-category">
              <div class="col-xl-12 box-title border-bottom">
                <div class="inner"><h2>Search for reviews</h2>
                </div>
              </div>
              <div class="container mb-3 mt-3">
                <div class="row">
                  <div class="col-10">
                    <b-nav-form @submit.prevent="getReviews(1)" class="searchBar w-100">
                      <b-input-group id="search">
                        <b-form-input v-model="searchInput" type="text" ref="searchInput" placeholder="Search for reviews by task or username"/>
                        <b-input-group-append>
                          <b-button variant="primary" size="sm" type="submit">
                            <i class="fa-solid fa-magnifying-glass"></i>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-nav-form>
                  </div>
                  <div class="col-2">
                    <select name="sort" id="sortAdmin" @change="getReviews(1)" class="form-control float-right" v-model="sort">
                      <option disabled="disabled" value="">Sort by</option>
                      <template v-for="sort in sortTypes">
                        <option :value="sort.value" :key="sort.value">{{ sort.name }}</option>
                      </template>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!LoadingPage">
          <div class="container" v-if="showNoResults">
            <b-alert v-model="showNoResults" variant="success" dismissible>
              No reviews found, please try again
            </b-alert>
          </div>
          <div class="container">
            <div class="col-xl-12 content-box" v-if="reviews.length > 0">
              <div class="row">
                <AdminReviewTable :reviews="reviews"/>
              </div>
            </div>
            <Pagination :perPage="reviewsPerPage" :currentPage="currentPage" :total="totalReviews" v-on:paginationUpdate="updatePagination" v-if="totalReviews > reviewsPerPage"
                        class="mt-3"/>
          </div>
        </div>
        <div v-else>
          <b-spinner class="mt-5 mb-2 mb-md-4" large label="Large Spinner"/>
          <div class="mb-5">Loading reviews</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '../../apiService'
import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb'
import Pagination from '../../components/Global/Pagination'
import AdminReviewTable from '../../components/Admin/Review/AdminReviewTable'

export default {
  name: 'AdminReviews',
  components: {
    Pagination,
    Breadcrumb,
    AdminReviewTable
  },
  data () {
    return {
      LoadingPage: true,
      reviews: [],
      currentPage: 1,
      reviewsPerPage: 24,
      totalReviews: 0,
      sort: '',
      showNoResults: false,
      searchInput: '',
      sortTypes: [
        { 'name': 'Task name (desc)', value: 'task.desc' },
        { 'name': 'Task name (asc)', value: 'task.asc' },
        { 'name': 'Author (asc)', value: 'buyer.asc' },
        { 'name': 'Author (desc)', value: 'buyer.desc' },
        { 'name': 'Task owner (asc)', value: 'seller.asc' },
        { 'name': 'Task owner (desc)', value: 'seller.desc' },
        { 'name': 'Rating (asc)', value: 'rating.asc' },
        { 'name': 'Rating (desc)', value: 'rating.desc' }
      ]
    }
  },
  mounted () {
    this.getReviews()
  },
  methods: {
    updatePagination (page = this.currentPage) {
      this.currentPage = page
      this.getReviews()
    },
    getReviews (page = null) {
      if (page !== null) {
        this.currentPage = page
      }
      this.LoadingPage = true
      ApiService.getReviewsAdmin(this.reviewsPerPage, this.currentPage, this.searchInput, this.sort).then(response => {
        this.reviews = response.data.data.results
        this.currentPage = response.data.data.pagination.page
        this.reviewsPerPage = response.data.data.pagination.per_page
        this.totalReviews = response.data.data.pagination.total
        this.LoadingPage = false
        if (this.reviews.length === 0) {
          this.showNoResults = true
        }
      }).catch(() => {
      })
    }
  }
}
</script>

<style scoped>
.container {
  max-width: 100%;
}

#search {
  width: 100% !important;
}

.cursor {
  cursor: pointer;
}

.form-inline {
  width: 100%;
}
</style>
